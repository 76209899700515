<template>
  <v-dialog
    v-model="view.toggle"
    overlay-opacity=".8"
    transition="slide-y-reverse-transition"
  >
    <v-card color="black">
      <v-card-title>
        {{ reset ? 'Esqueci a senha' : 'Recuperar acesso' }}
      </v-card-title>
      <v-card-text class="py-4">
        <p 
          class="body-1 mb-6"
        >
          {{ email.toggle ? 'Informe seu email abaixo para solicitar recuperação de acesso.' : view.feedback.text }}
        </p>

        <v-text-field 
          v-if="email.toggle"
          v-model="email.input" 
          :label="email.label" 
          :disabled="email.disabled||email.loading"
          :error-messages="email.error"
          hide-details="auto"
          outlined
          color="primary"
          class="my-2"
          @keyup="email.error = [];"
          @keyup.enter="handleForm()"
        />
        <m-field
          v-else
          v-model="token.input"
          :label="token.label"
          :validate="token.validate"
          :rules="token.rules"
          :outlined="false"
          dark
          color="primary"
        >
          <v-otp-input 
            v-model="token.input" 
            :label="token.label" 
            :disabled="token.disabled||token.loading"
            type="number"
            outlined
            color="primary"
            class="pt-2"
            @keyup="token.error = [];"
            @keyup.enter="handleForm()"
            @finish="handleForm()"
          />

        </m-field>
        <v-btn
          v-if="email.toggle"
          text
          small
          color="primary"
          @click="email.toggle = false;"
        >
          Já tenho código
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="grey"
          text
          @click="email.toggle ? view.toggle = false : email.toggle = true"
        >
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :disabled="btn.disabled||!validForm"
          :loading="email.loading||token.loading"
          @click="handleForm()"
        >
          {{ email.toggle ? 'Solicitar' : 'Validar' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>

</style>

<script>
  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'


  export default {
    name: 'ForgotPassword',

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      email: {
        toggle: true,
        label: 'Email',
        input: '',
        disabled: false,
        loading: false,
        error: []
      },
      token: {
        label: 'Código de acesso',
        input: '',
        rules: [
          v => (!!v && v!='') && /\d{6}/.test(v) || 'Código inválido'
        ],
        validate: v => !!v && v.length==6,
        disabled: false,
        loading: false,
        error: []
      },
      btn: {
        disabled: false,
      },
    }),

    props: {
      reset: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      view: sync('app/views@forgotPassword'),
      cpf: get('user/cpf'),
      toast: sync('app/toast'),

      validEmail () {
        const email = this.email;
        return email.input!==''&&/.+@.+\..+/.test(email.input);
      },
      validToken () {
        const token = this.token;
        return token.input!==''&&/\d{6}/.test(token.input);
      },
      validForm () {
        const email = this.email;
        const valid = email.toggle ? this.validEmail : this.validToken;
        return valid;
      }
    },

    methods: {
      ...services,

      handleForm () {
        if (this.email.toggle) {
          if (this.validEmail) {
            this.recoverToken();
            this.email.error = [];
          }else{
            this.email.error.push('Email inválido');
          }
        }else{
          if (this.validToken) {
            this.verifyToken();
            this.token.error = [];
          }else{
            this.token.error.push('Código inválido');
          }
        }
      },

      rawCPF (cpf) {
        return cpf.replace(/^[.-]+|[.-]+$|[.-]+/g, "");
      },

      recoverToken () {
        // server call mock
        this.email.loading = true;

        const cpf = this.rawCPF(this.cpf);
        const email = this.email.input;

        this.trackEvent('recover-password', 'requested');

        this.$api
          .get('/driver/recovery', { params: { cpf, email } })
          .then(response => {
            console.log('recoverToken => ',response);
            if(response.data.retorno==200){
              // Show success message
              this.email.loading = false;
              this.email.toggle = false;
              this.view.feedback.text = 'Enviamos por email um código de acesso de 6 dígitos. Confira seu email e digite o código abaixo:';
              this.view.feedback.toggle = true;

              this.trackEvent('recover-password', 'email sent');
            }else{
              // handle email not found
              this.email.error.push('Não temos cadastro com o email informado.');

              this.trackEvent('recover-password', 'email not registered');
            }
          })
          .catch(error => {
            console.log(error, 'Não foi possível recuperar sua senha. Tente novamente, por favor.');
            this.view.feedback.text = 'Erro desconhecido. Vamos investigar o que houve! 😊';
            this.view.feedback.toggle = true;
          })
          .finally(() => {
            this.email.loading = false;
          });
      },

      verifyToken () {
        // server call mock
        this.token.loading = true;

        const cpf = this.rawCPF(this.cpf);
        const token = this.token.input;
        const reset = !this.reset;

        this.trackEvent('recover-password', 'verify');

        this.$api
          .post('/driver/recovery', {
            cpf,
            token,
            reset
          })
          .then(response => {
            console.log('recoverToken => ',response);
            if(response.data.retorno==200){
              // Show success message
              this.view.toggle = false;
              this.email.toggle = false;
              this.token.input = null;
              this.token.loading = false;
              const { authToken } = response.data;
              if (this.reset) {
                // redirect to password reset
                this.$router.push(`/esqueci-senha/${cpf}/${token}`);
              }else{
                this.toggleToast(true, 'Código validado ✅');
              }
              this.$emit('validated', authToken);

              this.trackEvent('recover-password', 'token verified');
            }else{
              // handle email not found
              this.token.error.push('Código inválido');

              this.trackEvent('recover-password', 'token invalid');
            }
          })
          .catch(error => {
            console.log(error, 'Não foi possível validar o código. Tente novamente, por favor.');
            this.view.feedback.text = 'Erro desconhecido. Vamos investigar o que houve! 😊';
            this.view.feedback.toggle = true;
          })
          .finally(() => {
            this.token.loading = false;
          });
      },

    },

    components: {
      MField: () => import('@/components/mField'),
    }

  }
</script>
